import React from 'react';

import styles from './presentationBox.module.css';

import ReactMarkdown from 'react-markdown';

import { FaLinkedin } from "react-icons/fa";

function presentationBox({name, avatar, text, linkedinLink}) {

    return (
            <div className={styles.presentationBoxContainer}>
                <div>
                    <h1 className={styles.titlePBC}>{name}</h1>

                    <img align="left" src={avatar} alt={name} style={{height: 150, marginRight: 15}}/>

                    {/* {text.map((txt, index) => <p key={index} className={styles.descriptionPCB}>{txt}</p>)} */}
                    {/* <p className={styles.descriptionPCB}>{text}</p> */}

                    <ReactMarkdown
                        source={text}
                        transformImageUri={
                            uri => uri.startsWith('http') ?
                                uri : `${process.env.GATSBY_API_URL}${uri}`
                                    }
                    />
                </div>

                {linkedinLink && <div className={styles.iconContainer}>
                    <FaLinkedin className={styles.iconLinke} onClick={() => window.open(linkedinLink)} />
                </div>}
            </div>
    );
}

export default presentationBox;
