import React from "react";
import classNames from 'classnames';

import HeaderImage from '../../components/imageHeader';

import { graphql } from 'gatsby';
import { useIntl } from "gatsby-plugin-intl";

import Layout from "../../components/layout";

import ReactMarkdown from 'react-markdown';

import '../../styles/index.css';
import styles from '../../styles/consulting.module.css';
import styleser from '../../styles/servico.module.css';

import PresentationBox from "../../components/presentationBox";

const ErrosGesFinanStatupPage = ({ data }) => {

    const intl = useIntl();
    const dataValue = data.allStrapiMaterias.edges[1].node;

    const titulo = intl.locale === 'en' ? dataValue.tituloEn : dataValue.tituloPt;
    const texto = intl.locale === 'en' ? dataValue.textoEn : dataValue.textoPt;
    const isEnglish = intl.locale === 'en'

    return (
        <Layout titlePage={titulo}>

            <HeaderImage title={titulo} />

            <div className={classNames("container", styles.consultingContainer)}>
                <main>
                    <ReactMarkdown
                        className={styleser.boxServicoImg}
                        source={texto}
                        transformImageUri={
                            uri => uri.startsWith('http') ?
                                uri : `${process.env.GATSBY_API_URL}${uri}`
                        }
                    />

                    <section className={styles.divMainText}>
                        <PresentationBox name={dataValue.colaboradores.nome} avatar={dataValue.colaboradores.foto ? dataValue.colaboradores.foto.localFile.publicURL : ""} text={isEnglish && dataValue.colaboradores.textoEn ? dataValue.colaboradores.textoEn : dataValue.colaboradores.textoPt} linkedinLink={null} />
                    </section>
                </main>
            </div>
        </Layout>
    )
}

export const query = graphql`
query {
    allStrapiMaterias {
      edges {
        node {
            colaboradores {
                nome
                foto {
                    name
                    localFile {
                      publicURL
                    }
                }
                textoPt
                textoEn
                linkedinLink
            }
          tag
          textoEn
          textoPt
          tituloEn
          tituloPt
          dependence
          id
        }
      }
    }
}
`

export default ErrosGesFinanStatupPage;
